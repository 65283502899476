import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaSchool, FaMoneyBillWave, FaMoneyBill, FaSearch } from 'react-icons/fa';
import { FiDelete, FiEdit, FiEye, FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Table, Divider, Modal, Col, Select, Spin, Card, Statistic, Row, Tag, Descriptions, notification } from 'antd';
import { parse, set } from 'date-fns';
import { ca, tr } from 'date-fns/locale';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { getSupplies } from '../../API/StaticGetters';
const { Option } = Select;

function Fornecedor() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedKey, setSelectedKey] = useState('1')
    const [form] = Form.useForm();
    const [typeForm, setTypeForm] = useState('');
    const [modalForm, setModalForm] = useState(false);
    const [search, setSearch] = useState('');
    const [modalOptions, setModalOptions] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState();
    const [supplyOptions, setSupplyOptions] = useState([]);

    useEffect(() => {
        localStorage.setItem('title', 'Fornecedores');
        localStorage.setItem('type', '2');
        getSupply();
    }, [])

    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
    }

    //CRUD

    const getSupply = async () => {
        setLoading(true);
        let dados = await getSupplies();
        if (dados) {
            setSupplyOptions(dados);
        }
        setLoading(false);
    }

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await axios({
                method: "post",
                url: `${localStorage.getItem('url')}/api/organograma/supply/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                data: {
                    dados: {
                        nome: values.name,
                        nuit: values.nuit,
                        address: values.address,
                        contacto: values.contact,
                        banco: values.bank || '',
                        nib: values.account || '',
                        entID: JSON.parse(sessionStorage.getItem('user'))?.unidade?.code
                    }
                }
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'O fornecedor foi registado com sucesso',
                });
                setModalForm(false);
                getSupply();
            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: 'Ocorreu um erro ao registar o fornecedor',
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitEdit = async (values) => {
        try {
            setLoading(true);
            await axios({
                method: "put",
                url: `${localStorage.getItem('url')}/api/organograma/supply/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                data: {
                    dados: {
                        id: selectedExpense.id,
                        nome: values.name,
                        nuit: values.nuit,
                        address: values.address,
                        contacto: values.contact,
                        banco: values.bank || '',
                        nib: values.account || '',
                        entID: JSON.parse(sessionStorage.getItem('user')).entID
                    }
                }
            }).then(dat => {
                if (dat.status !== 200) {
                    throw Error('Error');
                }
                return dat
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'O fornecedor foi editado com sucesso',
                });
                setModalForm(false);
                getSupply();
            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: 'Ocorreu um erro ao editar o fornecedor',
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const putDelete = async () => {
        try {
            setLoading(true);
            await axios({
                method: "delete",
                url: `${localStorage.getItem('url')}/api/organograma/supply/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                params: {
                    id: selectedExpense.id
                }
            }).then(dat => {
                if (dat.status !== 200) {
                    throw Error('Error');
                }
                return dat
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'O fornecedor foi removido com sucesso',
                });
                setModalOptions(false);
                getSupply();
            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: 'Ocorreu um erro ao remover o fornecedor',
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }



    //Colunas da Tabela
    const columnsTable = [
        { title: 'Código', width: 90, dataIndex: 'id', align: 'center', key: 'id', sorter: (a, b) => a.id - b.id },
        { title: 'Nome', dataIndex: 'name', align: 'left', key: 'name' },
        { title: 'NUIT', dataIndex: 'nuit', align: 'center', key: 'nuit' },
        { title: 'Contacto', dataIndex: 'contact', align: 'center', key: 'contact' },
        {
            title: 'Actions', align: 'left', width: 100, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button className='bg-green-500 text-white' size='small' icon={<FiEdit />} onClick={() => goWork(record, 2)}></Button>
                    <Button className='bg-gray-500 text-white' size='small' icon={<FiDelete />} onClick={() => goWork(record, 3)}></Button>
                </div>
        }
    ];

    //Actions
    const goWork = (record, type) => {
        form.resetFields();
        try {
            setSelectedExpense(record);
        } finally {
            switch (type) {
                case 2:
                    form.setFieldsValue({
                        name: record.name,
                        nuit: record.nuit,
                        address: record.address,
                        contact: record.contact,
                        bank: record.bank,
                        account: record.account,
                    });
                    setTypeForm('edit');
                    setModalForm(true);
                    break;
                case 3:
                    setTypeForm('delete');
                    setModalOptions(true);
                    break;
                default:
                    break;
            }
        }
    }

    const handleAdd = () => {
        try {
            form.resetFields();
            setTypeForm('add');
        } finally {
            setModalForm(true);
        }
    }

    return (
        <div className='flex flex-col gap-4'>
            <Spin spinning={loading}>
                <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                    <p className='title-form'>Fornecedores</p>
                    <div className="flex flex-row items-center justify-between">
                        <div className='w-1/3 flex gap-2 items-center'>
                            <FaSearch />
                            <Input
                                placeholder="Pesquisa por Nome"
                                value={search}
                                className='input-form'
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <Button
                            className='button-in'
                            onClick={() => handleAdd()}
                            icon={<FaPlus />}
                            size='medium'
                        >
                            Registar fornecedor
                        </Button>
                    </div>
                    {selectedKey == '1' &&
                        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table
                                className='custom-table'
                                size='small'
                                columns={columnsTable}
                                dataSource={supplyOptions?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))}
                                pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                                locale={{
                                    emptyText: 'Sem Fornecedores',
                                }}
                                footer={() => `Total: ${supplyOptions?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase())).length}`}
                            />
                        </div>
                    }
                    <Modal
                        title={typeForm === 'add' ? <p className='title'>Registar Forcedor</p> : <p className='title'>Editar Fornecedor</p>}
                        visible={modalForm}
                        onCancel={() => setModalForm(false)}
                        footer={null}
                        width={700}
                    >
                        <Form
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onFinish={typeForm === 'add' ? handleSubmit : handleSubmitEdit}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="name"
                                        label="Nome"
                                        rules={[{ required: true, message: 'Por favor digite o nome' }]}
                                    >
                                        <Input className='input-form' placeholder='Digite o nome' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="nuit"
                                        label="Nuit"
                                        rules={[{ required: true, message: 'Por favor digite o nuit' }]}
                                    >
                                        <Input className='input-form' placeholder='Digite o nuit' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="address"
                                        label="Endereço"
                                        rules={[{ required: true, message: 'Por favor digite o endereço' }]}
                                    >
                                        <Input className='input-form' placeholder='Digite o endereço' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="contact"
                                        label="Contacto"
                                        rules={[{ required: true, message: 'Por favor digite o contacto' }]}
                                    >
                                        <Input className='input-form' placeholder='Digite o contacto' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        name="bank"
                                        label="Banco"
                                    >
                                        <Input className='input-form' placeholder='Digite o banco' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        name="account"
                                        label="NIB"
                                    >
                                        <Input className='input-form' placeholder='Digite o nib' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className='flex gap-2 w-full justify-end'>
                                <Button className='button-in' loading={loading} htmlType='submit'>
                                    Registar
                                </Button>
                                <Button className='button-out' onClick={() => setModalForm(false)}>
                                    Cancelar
                                </Button>
                            </div>
                        </Form>
                    </Modal>

                    <Modal
                        title={typeForm === 'delete' ? <p className='title'>Remover Fornecedor</p> : <p className='title'>Detalhes da Despesa</p>}
                        visible={modalOptions}
                        onCancel={() => setModalOptions(false)}
                        footer={null}
                        width={500}
                        centered
                    >
                        {typeForm === 'delete' && <>
                            <p className='text'>Tem a certeza que deseja remover o fornecedor?</p>
                            <div className='flex gap-2 w-full justify-end'>
                                <Button className='button-in' onClick={putDelete} loading={loading}>
                                    Remover
                                </Button>
                                <Button className='button-out' onClick={() => setModalOptions(false)}>
                                    Cancelar
                                </Button>
                            </div>
                        </>}
                    </Modal>
                </div>
            </Spin>
        </div>
    );
}

export default Fornecedor;