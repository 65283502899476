import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaPlus, FaSchool, FaMoneyBillWave, FaMoneyBill } from 'react-icons/fa';
import { FiDelete, FiEdit, FiEye, FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Table, Divider, Modal, Col, Select, Spin, Card, Statistic, Row, Tag, Descriptions, notification, List } from 'antd';
import { parse, set } from 'date-fns';
import { ca, tr } from 'date-fns/locale';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { getExpense, getExpenseParams, getExpenses, getSupplies, getUnitBalance, getUnitMoviments, getUnits } from '../../API/StaticGetters';
import ExpenseDetail from '../../Components/despesa/detail';
const { Option } = Select;

function Historico() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [modalView, setModalView] = useState(false)
    const [balance, setBalance] = useState()
    const [expense, setExpense] = useState()
    const [moviments, setMoviments] = useState()
    const [selectedMenu, setSelectedMenu] = useState('mov')

    useEffect(() => {
        localStorage.setItem('title', 'Histórico');
        localStorage.setItem('type', '2');
        getData();
    }, [])

    const getData = async (type) => {
        setLoading(true)
        let exps = await getExpenses(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id, 'All')
        let bnl = await getUnitBalance(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        let movs = await getUnitMoviments(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        setBalance(bnl)
        setData(exps)
        setMoviments(movs)
        setLoading(false)
    }

    //Colunas da Tabela
    const columnsTable = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id' },
        { title: 'Rubrica', dataIndex: 'expense_type', align: 'left', width: 80, render: (text, record) => record?.expense?.codigo, key: 'expense_type' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.nome, key: 'expense_type' },
        { title: 'Eixo/Objectivo', dataIndex: 'obj_estrategico', width: 80, render: (text, record) => record?.objective?.code, align: 'left', key: 'obj_estrategico' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 50, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{ backgroundColor: 'blue' }} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                </div>
        }
    ];


    //handles
    const goSee = async (record) => {
        let data = await getExpense(record.id)
        setExpense(data)
        setModalView(true)
    }

    const handleMenu = (menu) => {
        setSelectedMenu(menu)
    }

    return (
        <div className='flex flex-col gap-4'>
            <Spin spinning={loading}>
                <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                    <div className='menu-bar mt-2'>
                        <div className={`menu-bar-item ${selectedMenu === 'mov' ? 'active' : ''}`} onClick={() => handleMenu('mov')}>
                            <p className='menu-bar-item-text'>Movimentos</p>
                        </div>
                        <div className={`menu-bar-item ${selectedMenu === 'exp' ? 'active' : ''}`} onClick={() => handleMenu('exp')}>
                            <p className='menu-bar-item-text'>Despesas</p>
                        </div>
                    </div>
                    <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
                        <Col span={8} className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Unidade"
                                    value={balance?.unit?.name}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    prefix={<FaSchool />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Disponível"
                                    value={balance?.balance}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBillWave />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Pedente"
                                    value={balance?.pending}
                                    valueStyle={{
                                        color: 'BLUE',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBill />}
                                />
                            </Card>
                        </Col>
                    </div>
                    <Divider className='my-0' />
                    {selectedMenu === 'exp' &&
                        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table
                                size='small'
                                className='custom-table'
                                columns={columnsTable}
                                dataSource={data}
                                pagination={{ pageSize: 10 }}
                                locale={{
                                    emptyText: 'Sem processos de despesa'
                                }}
                            />
                        </div>
                    }
                    {selectedMenu === 'mov' &&
                        <List
                            itemLayout="horizontal"
                            dataSource={moviments}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={item.type === 'Entrada' ? <FiTrendingUp style={{ fontSize: '20px', color: 'green' }} /> : <FiTrendingDown style={{ fontSize: '20px', color: 'red' }} />}
                                        title={<a className=''>{item.description}</a>}
                                        description={<p className='text'>Saldo: {numeral(item.value).format('0,0.00')} MZN</p>}
                                    />
                                </List.Item>
                            )}
                        />
                    }
                    <Modal
                        title={<p className='title'>Detalhes da Despesa</p>}
                        visible={modalView}
                        onCancel={() => setModalView(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        <ExpenseDetail dados={expense} />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
}

export default Historico;