import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import { Card } from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { FaFileSignature, FaHistory, FaMoneyBill, FaUser, FaUserAlt, FaUserPlus, FaUsers} from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import { getNotifications } from '../API/getNotification';

const Menu = (props) => {

  const [notifications, setNotifications] = useState({
    pending_expense: 0,
    approved_expense: 0,
    pre_auth_expense: 0,
    transfer_expense: 0
  })

  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Plataforma');
    localStorage.setItem('type', '1');
    MyNotifications()
  }, [])

  const MyNotifications = async () => {
    await getNotifications()
    setNotifications({
      pending_expense: JSON.parse(sessionStorage.getItem('notifications')).pending_expense,
      approved_expense: JSON.parse(sessionStorage.getItem('notifications')).approved_expense,
      pre_auth_expense: JSON.parse(sessionStorage.getItem('notifications')).pre_auth_expense,
      transfer_expense: JSON.parse(sessionStorage.getItem('notifications')).transfer_expense
    })
  }

  const links = [
    {
      title: 'Registrar Despesa',
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/registrar-despesa',
      group: 11
    },
    {
      title: 'Registrar Despesa',
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/registrar-despesa',
      group: 13
    },
    {
      title: 'Aprovar Despesas',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/aprovar-despesas',
      group: 8,
      type:'pending_expense'
    },
    {
      title: 'Vistoria de Despesas',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/vistoria-despesas',
      group: 9,
      type:'approved_expense'
    },
    {
      title: 'Autorizar Despesas',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/autorizar-despesas',
      group: 7,
      type:'pre_auth_expense'
    },
    {
      title: 'Autorizar Despesas',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/autorizar-despesas',
      group: 2,
      type:'pre_auth_expense'
    },
    {
      title: 'Transferir Despesas',
      icon: <FaMoneyBill style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/transferir-despesas',
      group: 10,
      type:'transfer_expense'
    },
    {
      title: 'Transferir Despesas',
      icon: <FaMoneyBill style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/transferir-despesas',
      group: 13,
      type:'transfer_expense'
    },
    {
      title: 'Fornecedores',
      icon: <FaUsers style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/fornecedores',
      group: 11
    },
    {
      title: 'Fornecedores',
      icon: <FaUsers style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/fornecedores',
      group: 13
    },
    {
      title: 'Histórico',
      icon: <FaHistory style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/historico',
      group: 'All'
    },
    {
      title: 'Meu Perfil',
      icon: <FaUserAlt style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/meu-perfil',
      group: 'All'
    }
  ]

  const filteredLinks = links.filter(link => link.group === 'All' || link.group===parseInt(sessionStorage.getItem('access')))

  const getCurrentNotification = (type) => {
    switch (type) {
      case 'pending_expense':
        return notifications.pending_expense
      case 'approved_expense':
        return notifications.approved_expense
      case 'pre_auth_expense':
        return notifications.pre_auth_expense
      case 'transfer_expense':
        return notifications.transfer_expense
      default:
        return 0
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4  gap-4 p-2 sm:p-8">
        {filteredLinks.map((link, index) => (
          <Link to={link.link} key={index}>
            <Card
              bordered={false}
              hoverable
              cover={link.icon}
              className="relative"
            >
              <div className="text-sm sm:text-lg text-center">{link.title}</div>
              <p className={`absolute flex justify-center items-center top-0 right-7 w-4 h-4 bg-orange-500 text-white rounded-full ${link.type ? 'visible' : 'hidden'}`}>
                  {getCurrentNotification(link.type)}
                </p>
            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Menu;