import axios from 'axios';
import { notification } from 'antd';

export const getProfilePicture = async () => {
    await axios.get(localStorage.getItem('url') + '/api/user/picture/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`,
            },
            responseType: 'blob'
        })
        .then(response => {
            sessionStorage.setItem('profile', URL.createObjectURL(response.data))
        }).catch(err => {
            
        }
        )
}

export const getUnits = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar estâncias'
            })
        })
    return dados;
}

export const getUnit = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar estância'
            })
        })
    return dados;
}

export const getUsers = async (tipo) => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/admin/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params: {
                type: tipo
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuários'
            })
        })
    return dados;
}

export const getUser = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/user/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuário'
            })
        })
    return dados;
}

export const getHistory = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/history/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar historico'
            })
        })
    return dados;
}

export const getSupplies = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/organograma/supply/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar fornecedores'
            })
        })
    return dados;
}

export const getExpenseParams = async (type) => {
    // 1 - Tipo de despesa
    // 2 - Eixos
    // 3 - Objectivo Estrategico
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/const/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                type:type
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getExpenses = async (id,type) => {
    // 1 - Pendente
    // 2 - Aprovado
    // 4 - Autorizado

    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id,
                type:type
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getExpense = async (pk) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/' + pk + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getUnitBalance = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/finance/balance/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getFundRequest = async (id,type) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/fundrequest/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                unit_id:id,
                type:type
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getUnitMoviments = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/finance/moviment/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id,
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados.reverse();
}

export const getCountNotifications =async()=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/count/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}